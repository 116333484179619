const topOffset = 18;
const defaultLocale = "et";

export { topOffset, defaultLocale };

export enum ViewLinks {
  Messages = "/messages",
  Readings = "/readings",
  Invoices = "/invoices",
  Association = "/association",
  Settings = "/settings",
  AddPerson = "/settings/apartment/add-person",
  UpdatePassword = "/settings/user/update-password",
  Entrances = "/entrances"
}

export const ViewLinksWithPrivatePrefix: Record<keyof typeof ViewLinks, string> = Object.fromEntries(
  Object.entries(ViewLinks).map(([key, value]) => [key, `/private${value}`])
) as Record<keyof typeof ViewLinks, string>;
